import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Montserrat\",\"arguments\":[{\"weight\":[\"400\",\"500\",\"600\"],\"variable\":\"--font-montserrat\",\"style\":[\"normal\"],\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"montserrat\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/src/components/aosComponent/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CookieBannerWrapped"] */ "/opt/build/repo/src/components/cookieBanner/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CopyTextButton"] */ "/opt/build/repo/src/components/copyTextButton/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GoogleAnalyticsWrapped"] */ "/opt/build/repo/src/components/googleAnalytics/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/opt/build/repo/src/components/header/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeroLink"] */ "/opt/build/repo/src/components/heroLink/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LandingHeroLink"] */ "/opt/build/repo/src/components/landingHeroLink/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Spinner"] */ "/opt/build/repo/src/components/spinner/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/styles/components/heading.scss");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/styles/components/footer.scss");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/styles/components/fixedWhatsapp.scss");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/styles/sections/landingHero.scss");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/styles/index.scss");
