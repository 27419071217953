'use client';

import { getLocalStorage, setLocalStorage } from '@/lib/storageHelper';
import Link from 'next/link';
import { Suspense, useEffect, useState } from 'react';

export function CookieBanner() {
	const [cookieConsent, setCookieConsent] = useState<null | boolean>(null);
	useEffect(() => {
		const storedCookieConsent = getLocalStorage('cookie_consent', null);
		setCookieConsent(storedCookieConsent);
	}, [setCookieConsent]);
	useEffect(() => {
		const newValue = cookieConsent ? 'granted' : 'denied';
		try {
			window.gtag('consent', 'update', {
				'analytics_storage': newValue
			});
		} catch (error) {
			console.log(error);
		}

		setLocalStorage('cookie_consent', cookieConsent);
	}, [cookieConsent]);

	return (
		<Suspense>
			<div
				className={`my-10 mx-auto max-w-max md:max-w-screen-sm
      fixed bottom-0 left-0 right-0 
      ${
				cookieConsent !== null ? 'hidden' : 'flex'
			} px-3 md:px-4 py-3 justify-between items-center flex-col sm:flex-row gap-4  
      bg-gray-700 rounded-lg shadow text-2xl`}>
				<div className="text-center">
					<Link href="/info/cookies">
						<p className="text-white">
							Usamos <span className="font-bold text-primary">cookies</span> en
							el sitio.
						</p>
					</Link>
				</div>

				<div className="flex gap-2">
					<button
						className="px-5 py-2 text-gray-300 rounded-md border-gray-900"
						onClick={() => setCookieConsent(false)}>
						Rechazar
					</button>
					<button
						className="bg-primary px-5 py-2 text-black rounded-lg"
						onClick={() => setCookieConsent(true)}>
						Permitir Cookies
					</button>
				</div>
			</div>
		</Suspense>
	);
}
