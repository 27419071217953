'use client';
import { Suspense } from 'react';
import { CookieBanner } from './CookieBanner';

export const CookieBannerWrapped = () => {
	return (
		<Suspense>
			<CookieBanner />
		</Suspense>
	);
};
