'use client';
import { Suspense } from 'react';
import { GoogleAnalytics } from './GoogleAnalytics';

export const GoogleAnalyticsWrapped = ({ gaId }: { gaId: string }) => {
	return (
		<Suspense>
			<GoogleAnalytics gaId={gaId} />
		</Suspense>
	);
};
